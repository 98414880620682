@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"), url("../fonts/Satoshi-Regular.ttf") format("truetype"),
    url("../fonts/Satoshi-Regular.woff") format("woff"),
    url("../fonts/Satoshi-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.admin-fonts {
  font-family: "Satoshi";
  font-weight: "normal";
}

.admin-login-wrapper {
  width: -webkit-fill-available;
  height: 100%;
  position: absolute;
}

/* admin dashboard css start */
.quix-admin-layout {
  display: flow-root;
  float: right;
  width: 100%;
  position: relative;
}

.quix-admin-layout-inner {
  padding-left: 326px;
  background: #f0f1f2;
}

.quix-admin-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.side-bar-footer {
  padding: 10px 6px 0;
}

.quix-admin-sidebar-outer {
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: #ffffff;
  width: 326px;
  min-height: calc(100vh - 0px);
  height: 100%;
  padding: 10px 20px;
  border-right: 1px solid #eaecf0;
}

.quix-admin-layout-content {
  padding: 2%;
  min-height: calc(100vh - 0px);
  height: 100%;
  /* overflow: auto; */
}

.manage-user-screenshot-and-video .quix-admin-layout-content {
  padding: 0px;
}

.quix-admin-menu {
  padding: 8px 10px;
  margin-bottom: 6px;
  width: 100%;
  border-radius: 6px;
}

.quix-admin-menu.active {
  background-color: #0052b4;
}

.quix-admin-logo {
  margin: 20px 0px 25px;
}

.quix-admin-logo img {
  width: auto;
  height: 46px;
}

.quix-admin-menu span.quix-admin-main-menu,
.quix-admin-menu span.quix-admin-main-menu a {
  text-decoration: none;
  color: #344054;
  font-size: 16px;
  cursor: pointer;
  opacity: unset;
  display: block;
  width: 100%;
  position: relative;
  padding: 0px;
}

.quix-admin-menu span.quix-admin-main-menu a::before {
  content: "";
  height: 26px;
  width: 10px;
  background-image: url("../images/menu-arrow.png");
  background-repeat: no-repeat;
  top: 50%;
  right: 0%;
  position: absolute;
  background-size: 100% 65%;
  background-position: center;
  transform: translate(-50%, -50%);
}

.quix-admin-menu.active span.quix-admin-main-menu a::before {
  background-image: url("../images/menu-arrow-white.png");
}

.quix-admin-menu span.quix-admin-main-menu a img,
.setting-icon img {
  width: auto;
  height: 22px;
  margin-right: 10px;
}

.quix-admin-menu.active span.quix-admin-main-menu a:hover,
.quix-admin-menu.active span.quix-admin-main-menu a {
  color: #fff;
}

.quix-admin-sub-menu {
  margin-top: 5px;
}

.quix-admin-sub-menu ul li {
  padding: 6px 10px;
  cursor: pointer;
  margin-bottom: 4px;
  border-radius: 6px;
  color: #fff;
}

.quix-admin-sub-menu ul li span,
.quix-admin-sub-menu ul li span a {
  opacity: unset;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  display: block;
  width: 100%;
}

.quix-admin-sub-menu ul li:hover,
.quix-admin-sub-menu ul li.active {
  background-color: #000;
}

.quix-dashboard-details-card .card h6 {
  font-weight: 600;
  text-transform: uppercase;
}

.quix-counting-number {
  font-weight: 600;
  margin: 0px;
}

.side-user-profile {
  border-top: 1px solid #eaecf0;
  padding: 15px 0px;
  margin-top: 15px;
}

.user-details {
  margin-left: 10px;
}

.user-details h6 {
  font-size: 16px;
  font-weight: 600;
}

.user-details p {
  font-size: 14px;
  color: #667085;
}

.user-details h6,
.user-details p {
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

.side-user-profile img {
  width: 20px;
  cursor: pointer;
}

.side-user-profile .user-profile {
  width: 46px;
  border-radius: 50%;
  background-color: #667085;
  height: 46px;
  overflow: hidden;
}

.side-user-profile .user-profile img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.password-field {
  position: relative;
}

.password-field-eyes {
  position: absolute;
  right: 12px;
  top: 6px;
  cursor: pointer;
  height: 26px;
  width: 26px;
}

.password-field-eyes img {
  height: 100%;
  width: auto;
}

/* admin dashboard css end */
/* table css start */
.data-content-section {
  padding: 2%;
}

.data-content-section .quix-data-table.card {
  box-shadow: 0px 0px 5px 2px #00000014;
}

.quix-data-table .card-header {
  padding: 18px 20px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaecf0;
}

.quix-data-table .card-header h5 {
  font-weight: 600;
}

.action-btn .btn {
  padding: 4px 10px;
  border: 1px solid #d6e5ff;
  font-size: 14px;
  height: 33px;
  color: #000;
  background-color: #ffff;
  border-radius: 4px;
}

.action-btn .btn img {
  height: 16px;
  margin-right: 6px;
}

.action-btn .btn.delete-btn {
  color: #d33636;
  background-color: #ffff;
}

.table-profile img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.table-profile h6 {
  font-size: 14px;
  color: #3a3e47;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
}

.show-table-record .quix-select-show-record {
  font-size: 14px;
  padding: 6px 10px;
  border: 1px solid #d6e5ff;
}

label.col-sm-12.col-form-label.text-start,
label.col-form-label {
  font-size: 14px;
}

/* management css start */
.management-header h4 {
  font-weight: 600;
}

.management-header p {
  font-size: 16px;
  margin: 0px;
  color: #667085;
}

.management-filters button.btn.management-filter-btn {
  padding: 4px 10px;
  border: 1px solid #98a2b3;
  border-radius: 6px;
  font-size: 14px;
  margin: 25px 0 20px;
  margin-right: 10px;
  color: #000;
  font-weight: 600;
  background-color: transparent;
  box-shadow: none;
}

.management-filters button.btn.management-filter-btn.active {
  background-color: #d6e5ff;
  border-color: #d6e5ff;
}

/* management css end */
/* statistics css start */
.table-statistics {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  box-shadow: 0px 0.43px 3.28px 0.4px #00000026;
}

.statistics-header h5 {
  font-weight: 600;
  margin: 0px;
}

.statistics-header a {
  color: #3e7bd7;
  font-size: 16px;
  padding: 0px;
}

.statistics-content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 16px;
}

.statistics-card {
  border-radius: 6px;
  border: 1px solid #c8c8c8;
  padding: 4px 6px;
  gap: 10px;
  font-size: 14px;
}

.statistics-card h6 {
  font-weight: 600;
  margin: 0px;
  border-right: 2px solid #c8c8c8;
  padding-right: 10px;
}

.statistics-card span {
  color: #667085;
}

/* statistics css end */
/* table css start md */
.table-header-actions .btn.table-header-action-btn,
.table-header .search-bar input {
  padding: 4px 10px;
  border: 1px solid #d6e5ff;
  font-size: 14px;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.table-header .search-bar input {
  width: 260px;
  height: 36px;
  color: #344054;
}

.table-header .search-bar input::placeholder {
  padding-left: 24px;
  background: url("../images/search-tbl-Icon.png");
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: 0px 50%;
}

.table-header .search-bar button.btn.search-users-btn {
  padding: 6px 10px;
  border: 1px solid #d6e5ff;
  font-size: 14px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 36px;
  color: #000;
  background-color: #d6e5ff;
}

.table-header-actions .btn.table-header-action-btn,
.table-header .search-bar button.btn.search-users-btn:focus,
.table-header .search-bar input:focus,
.show-table-record .quix-select-show-record:focus {
  box-shadow: none;
  outline: none;
}

.table-header-actions .btn.table-header-action-btn img {
  width: 20px;
  height: auto;
}

.quix-data-table.card {
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 0.43px 3.28px 0.4px #00000026;
}

.quix-data-table.card,
.quix-data-table .card-footer {
  border: none;
  background-color: #ffffff;
}

table .shorting-img {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}

/* table css end md*/
/* table css start */
.quix-data-table .quix-table-data-outer {
  width: 100%;
  overflow-x: auto;
}

.quix-data-table .quix-table-data-outer::-webkit-scrollbar,
.quix-pricing-edit-details::-webkit-scrollbar {
  height: 10px;
  width: 6px;
}

.quix-data-table .quix-table-data-outer::-webkit-scrollbar-thumb,
.quix-pricing-edit-details::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 10px;
  width: 2px;
}

.quix-data-table .quix-table-data-outer::-webkit-scrollbar-thumb:hover,
.quix-pricing-edit-details::-webkit-scrollbar-thumb:hover {
  background-color: #000000e1;
  cursor: pointer;
}

.quix-data-table table {
  width: max-content;
  border-collapse: collapse;
  min-width: 100%;
  border-color: #ececec;
}

.quix-data-table .quix-table-action-btn {
  padding: 4px 8px;
  border: 1px solid #000;
  color: #fff;
  background-color: #fff;
  font-size: 12px;
  margin: 0 3px;
  border-radius: 5px;
}

.quix-data-table .quix-table-action-btn img {
  width: 20px;
}

.quix-data-table .quix-table-action-btn {
  padding: 3px 10px;
  font-size: 14px;
  border-radius: 5px;
  color: #ffff;
  font-weight: 600;
}

.quix-data-table .quix-table-action-btn.inactive-status {
  background-color: #ffe2e2;
  color: #600303;
  border: 1px solid #b71212;
}

.quix-data-table .quix-table-action-btn.active-status {
  background-color: #e2fff2;
  color: #036034;
  border: 1px solid #12b76a;
}

.quix-data-table .quix-table-img-card {
  height: 60px;
  width: 60px;
  border-radius: 5px;
  overflow: hidden;
}

.quix-data-table .quix-table-img-card img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.quix-data-table table th,
.quix-data-table table td {
  font-size: 14px;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.quix-data-table table th {
  background-color: #f8f8f8;
  font-weight: 600;
  color: #667085;
}

.quix-data-table table th:last-child,
.quix-data-table table td:last-child {
  width: 80px;
  text-align: center;
}

.quix-data-table table th:first-child,
.quix-data-table table td:first-child,
.quix-data-table table th:nth-child(2),
.quix-data-table table td:nth-child(2) {
  width: 60px;
  text-align: center;
}

.quix-membership-content .quix-data-table table th:nth-child(2) {
  width: 120px;
}

.quix-data-table table input.check-items {
  width: 18px;
  height: 18px;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
}

.quix-data-table table .action-cell .edit-action-cell {
  position: relative;
  width: max-content;
  margin: 0 auto;
}

.quix-data-table table .action-cell img.action-img {
  height: 28px;
  border: 1px solid #98a2b3;
  border-radius: 6px;
  padding: 6px 10px;
  cursor: pointer;
}

.edit-table-action {
  position: absolute;
  top: 60%;
  left: 0%;
  transform: translate(-100%, -10%);
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 1.43px 4.28px 1.43px #00000026;
  height: max-content;
  width: 180px;
  z-index: 111;
  transition: all 0.6s;
  display: none;
  /* bottom: -80%; */
  margin-bottom: 30px;
}

.column-action {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #98a2b3;
  font-size: 14px;
  color: #344054;
  font-weight: 600;
  cursor: pointer;
}

.column-action a {
  width: 100%;
  display: flex;
  color: #344054;
  text-decoration: none;
}

.column-action.delete-action {
  color: #d33636;
}

.column-action:last-child {
  border-bottom: none;
}

.column-action img {
  width: 24px;
  height: 24px;
}

.custom-switch .form-check-input {
  height: 20px;
  width: 36px;
  border-radius: 5px;
}

.custom-switch .form-check-input:checked {
  background-color: #12b76a;
  border-color: #12b76a;
}

.quix-data-table table .action-cell .edit-action-cell:hover>.edit-table-action {
  display: block;
}

/* .quix-data-table table tbody tr:last-child .edit-table-action,
.quix-data-table table tbody tr:nth-last-child(2) .edit-table-action,
.quix-data-table table tbody tr:nth-last-child(3) .edit-table-action,
.quix-data-table table tbody tr:nth-last-child(4) .edit-table-action {
  transform: translate(-100%, -94%);
} */

/* .quix-data-table table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
} */

.quix-data-table table tbody tr:hover {
  background-color: #f5f5f5;
}

.page-coutings {
  font-size: 14px;
  color: #3a3e47;
  font-weight: 600;
}

.table-paginations {
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  overflow: hidden;
}

.table-paginations img {
  width: 16px;
  height: 14px;
  margin: 0px 8px;
}

.quix-table-page-btn {
  padding: 6px 14px;
  background-color: #fff;
  font-size: 14px;
  color: #3a3e47;
  font-weight: 600;
  border: none;
  border-right: 1px solid #d0d5dd;
  cursor: pointer;
}

.quix-table-page-btn.active {
  background-color: #d6e5ff;
}

.quix-table-page-btn:last-child {
  border-right: none;
}

.quix-table-page-btn:disabled {
  opacity: 0.6;
}

.quix-form-outer .form-control {
  border-radius: 5px;
}

.quix-form-btn-outer .quix-form-btn {
  padding: 8px 36px;
  border: 1px solid #2f51b5;
  border-radius: 5px;
  margin: 0 6px;
  font-size: 13px;
}

.btn-loader-icons {
  width: auto;
  height: 30px;
}

.quix-form-btn-outer .quix-submit-btn {
  background-color: #2f51b5;
  color: #fff;
}

.quix-form-btn-outer .quix-form-cancel-btn {
  background-color: #fff;
  color: #000;
  font-weight: 600;
}

/* edit user */
div#quix-dashboard-popup.quix-edit-user .quix-edit-user-outer {
  width: 450px;
  background-color: #fff;
}

.table-status-btn {
  padding: 3px 10px;
  font-size: 14px;
  border-radius: 5px;
  color: #ffff;
  font-weight: 600;
}

.table-status-btn.table-status-pending {
  background-color: #0202504d;
  color: #0202509e;
  border: 1px solid #0202509e;
}

.table-status-btn.table-status-suspend {
  background-color: #ffe2e2;
  color: #600303;
  border: 1px solid #b71212;
}

.table-status-btn.table-status-active {
  background-color: #e2fff2;
  color: #036034;
  border: 1px solid #12b76a;
}

.table-status-btn.table-status-default {
  background-color: #f5f5f5;
  color: #3f3f3f;
  border: 1px solid #7d7d7d;
}

.manage-user-screenshot-and-video .management-filters button.btn.management-filter-btn {
  margin-top: 0px;
}

/* edit pricing plans */
div.quix-dashboard-popup-inner.quix-edit-pricing-plan-outer,
div.quix-dashboard-popup-inner.quix-edit-screenshot-outer {
  width: 650px;
  max-height: 700px;
}

.quix-pricing-edit-details {
  overflow: auto;
  height: 620px;
  padding-bottom: 30px;
}

.quix-edit-pricing-plan-outer .quixy-feature-row-outer {
  grid-template-columns: repeat(1, 1fr);
}

.features-colums {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

/*  */
/* .quix-featured-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: justify;
} */

.quixy-feature-row-outer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px 20px;
  text-align: justify;
  height: max-content;
}

.quix-feature-row {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  height: max-content;
}

.quix-feature-row .quix-feature-row-title {
  background-color: #f8f9fc;
  padding: 10px;
  color: #000;
  font-weight: 600;
}

.quix-feature-row .quix-feature-row-title label {
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
}
.quix-feature-row .quix-feature-row-title button.accordion-button{
  padding: 0px;
  width: max-content;
  background: transparent;
}

.quix-feature-row .quix-feature-row-title button.accordion-button:focus{
  background: transparent;
  outline: none;
  box-shadow: none;
}

.quix-feature-row .quix-feature-row-title button.accordion-button:not(.collapsed){
  box-shadow: none;
}

.accordion-item:not(:first-of-type) {
  border-top: 1px solid #ddd;
}

.quix-featured-row {
  padding: 14px;
  border-top: 1px solid #ddd;
}

/* login page */
.quix-admin-login-form-outer {
  padding: 5%;
}

.admin-login-card.card {
  width: 500px;
  border: 1px solid #2f51b5;
}

.admin-login-card.card .card-header {
  background-color: #2f51b5;
  color: #fff;
}

.admin-login-card.card .form-control {
  border-radius: 5px;
  border: 1px solid #2f51b5;
  padding-left: 40px;
}

.form-control:focus {
  box-shadow: none;
}

/*  */
.custom-admin-login-details .quix-login-email,
.custom-admin-login-details .quix-login-password {
  position: relative;
}

.custom-admin-login-details .quix-login-email::after,
.custom-admin-login-details .quix-login-password::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 36px;
  width: 36px;
  background-repeat: no-repeat;
  background-position: 10px center;
}

.custom-admin-login-details .quix-login-email::after {
  background-image: url("../images/email-icon.png");
}

.custom-admin-login-details .quix-login-password::after {
  background-image: url("../images/password-icon.png");
}

.user-password-filed .password-eyes {
  position: relative;
}

.password-eyes img {
  height: 26px;
  width: 26px;
  position: absolute;
  right: 12px;
  top: 6px;
  cursor: pointer;
}

/*  */

/* admin-confirm-popup */
div#quix-dashboard-popup.admin-confirm-popup .quix-edit-user-outer {
  width: 400px;
  background-color: #fff;
  border-radius: 8px;
}

div#quix-dashboard-popup.admin-confirm-popup .confirm-box-icon img {
  height: 80px;
  width: auto;
  margin-bottom: 10px;
}

div#quix-dashboard-popup.admin-confirm-popup .quix-form-filed {
  background-color: #efefef;
  padding: 16px 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

div#quix-dashboard-popup.admin-confirm-popup .quix-dashboard-popup-body,
div#quix-dashboard-popup.admin-confirm-popup div.quix-dashboard-popup-inner {
  margin: 0px;
  padding: 0px;
}

div#quix-dashboard-popup.admin-confirm-popup .quix-dashboard-popup-body p {
  font-weight: 600;
}

div#quix-dashboard-popup.admin-confirm-popup .quix-cancel-edit {
  border: 1px solid #98a2b3;
  background-color: transparent;
  padding: 6px 40px;
  border-radius: 6px;
}

div#quix-dashboard-popup.admin-confirm-popup .edit-user-detail-btn {
  border: 1px solid #0052b4;
  background-color: #0052b4;
  padding: 6px 40px;
  border-radius: 6px;
}

/* success-modal */
.message-modal img {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.message-title h5 {
  color: #fff;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 6px;
  font-size: 14px;
}

.message-modal p {
  color: #2f7750;
  font-size: 13px;
  margin: 0;
}

.Toastify__close-button {
  color: #fff !important;
  opacity: unset;
}

/* manage-user-screenshot-and-video-content */
.manage-user-screenshot-and-video-content {
  background-color: #fff;
  padding: 2%;
  padding-bottom: 16px;
}

.theme-btn-outline.btn {
  padding: 8px 20px;
  font-size: 14px;
  text-decoration: none;
  border: 2px solid #98a2b3;
  color: #344054;
  border-radius: 6px;
  font-weight: 600;
  height: max-content;
}

.theme-btn-outline.btn:hover {
  opacity: 0.8;
  color: #344054;
}

.user-manage-bk-btn.theme-btn-outline.btn img {
  height: 20px;
  width: auto;
  margin-right: 6px;
}

/*  */
.user-profile-details-section {
  margin: 20px 0 0;
}

.user-profile-details-section .profile-card {
  height: 100px;
  width: 100px;
  border-radius: 8px;
  overflow: hidden;
}

.user-profile-details-section .profile-card img {
  height: 100%;
  width: 100%;
}

.profile-detail .user-name-and-status h4 {
  font-size: 22px;
  font-weight: 600;
  color: #344054;
}

.profile-detail .user-name-and-status span {
  font-size: 16px;
  font-weight: 600;
  color: #46705c;
}

.profile-detail img {
  height: 20px;
  width: 20px;
}

.profile-detail a {
  color: #3e7bd7;
  margin-left: 10px;
  font-weight: 500;
}

.profile-detail .statistics-card img {
  margin-right: 6px;
}

.theme-blue-btn {
  border: 2px solid #0052b4;
  background-color: #0052b4;
  color: #fff;
  height: max-content;
  padding: 4px 20px;
  font-size: 14px;
  border-radius: 6px;
}

.theme-blue-btn:hover {
  color: #fff;
  opacity: 0.9;
}

/* media-card-banner */
#media-content-card .media-card-banner {
  height: 60px;
  width: 100px;
  border-radius: 6px;
  overflow: hidden;
}

#media-content-card .media-card-banner img {
  height: 100%;
  width: 100%;
}

#media-content-card .media-content {
  padding: 10px 10px;
}

#media-content-card .media-content p {
  font-size: 14px;
  font-weight: 600;
  color: #3a3e47;
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

#media-content-card .media-content span {
  font-size: 13px;
  color: #787a7d;
}

/* admin-popup-modal */
.admin-popup-modal {
  position: fixed;
  right: 40px;
  top: 40px;
  height: auto;
  width: 400px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 1px 10px -1px #00000040;
}

.close-admin-popup {
  position: absolute;
  right: 0px;
  top: 0px;
  transform: translate(10px, -10px);
  cursor: pointer;
}

.close-admin-popup img {
  height: 30px;
  width: 30px;
}

.admin-popup-modal input[type="text"],
.admin-popup-modal input[type="password"] {
  border-radius: 5px;
}

/* media-card */
.user-card .user-card-header h5,
.media-card .media-card-header h5 {
  color: #344054;
  font-weight: 600;
  margin-bottom: 16px;
  font-size: 18px;
}

.media-card-content {
  height: 192px;
  width: 100%;
}

.media-card-content img {
  width: 100%;
  height: 100%;
}

.media-card-footer {
  margin-top: 16px;
  border-top: 1px solid #e3e3e3;
}

p.video-details-lable {
  color: #344054;
  font-weight: 600;
  padding-top: 10px;
}

.video-details .video-details-column span {
  color: #667085;
  font-size: 14px;
}

.video-details .video-details-column h6 {
  color: #344054;
  font-size: 16px;
}

/* user-profile */
#user-card.admin-popup-modal.user-card {
  width: 600px;
}

button.btn.card-action-btn {
  padding: 4px 10px;
  font-size: 14px;
}

p.card-details-lable {
  font-size: 16px;
  color: #98a2b3;
  margin-bottom: 6px;
}

.card-details-box {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 10px;
}

.user-profile {
  height: 80px;
  width: 80px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #eeeeee;
  position: relative;
}

.user-profile img {
  height: 100%;
  width: 100%;
}

.update-plan-btn button.btn{
  padding: 6px 10px;
  border: 1px solid #2f51b5;
}

.text-clip {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input.upload-user-image {
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.upload-profile-sec button.btn {
  padding: 4px 10px;
  font-size: 14px;
}

.upload-profile-sec button.btn.upload-profile-btn {
  background: #0052b4;
  color: #fff;
  cursor: pointer;
}

.upload-profile-sec button.btn.remove-profile-btn {
  border: 1px solid #98a2b3;
}

.upload-profile-sec p {
  font-size: 14px;
  margin-top: 12px;
  color: #98a2b3;
}

.content-column .title {
  font-size: 16px;
  font-weight: 600;
}

.delete-account-column span {
  font-size: 14px;
}

.delete-account-column button.btn {
  background-color: #ffe2e2;
  border: 1px solid#600303;
  color: #600303;
}

.edit-pricing-plan .product-upload-img {
  padding: 10px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
}

.product-upload-img .upload-card {
  height: 80px;
  width: 80px;
  position: relative;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
}

.product-upload-img .upload-card img {
  object-fit: contain;
}

.product-upload-img .upload-card.upload-btn {
  height: max-content;
  width: max-content;
  border: none;
}

.btn.upload-photo-btn,
.btn.remove-photo-btn {
  background: #0052b4;
  color: #fff;
  cursor: pointer;
  padding: 4px 10px;
  font-size: 14px;
  display: block;
  cursor: pointer;
}

.pricing-plans-status-sec .form-switch .form-check-input {
  height: 30px;
  width: 60px;
  border-radius: 6px;
  margin-right: 10px;
  margin-top: 0;
}

.product-upload-img p {
  font-size: 14px;
  margin-top: 12px;
  color: #98a2b3;
}

.plan-loader-outer {
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn.remove-photo-btn {
  border: 1px solid #98a2b3;
  background: transparent;
  color: #000;
}

.product-upload-img input.upload-pricing-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

/* pricing card */
.pricing-plans-card {
  border-radius: 6px;
  border-radius: 6px;
  overflow: hidden;
  background-color: transparent;
  border: none;
}

.pricing-plans-details-sec {
  border-top: 1px solid #00000026;
  padding-top: 20px;
}

.pricing-plans-card .card-header {
  border-bottom: none;
  padding: 12px 20px;
  background-color: transparent;
}

.pricing-plans-card .card-header h5 {
  margin: 0;
  font-weight: 600;
}

.pricing-product-card-bnnr {
  height: 80px;
  width: 80px;
  border-radius: 10px;
  overflow: hidden;
}

.card.pricing-product-card {
  flex-direction: row;
  border: none;
  margin: 16px 0;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid transparent;
  background-color: transparent;
}

.card.pricing-product-card.active {
  box-shadow: 0px 0.91px 2.73px 0.4px #00000026;
  background-color: #fff;
  transition: all 0.3s;
}

.pricing-product-card-details {
  display: flex;
  align-items: center;
  flex: 2;
  padding: 15px;
}

.pricing-product-price-details label {
  font-size: 14px;
  color: #6c798e;
  margin-bottom: 6px;
}

.pricing-product-price-details h6 {
  color: #344054;
  font-size: 18px;
  font-weight: 600;
}

.product-plan-edit-btn {
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  background-color: #fff;
  color: #424548;
  box-shadow: 0px 0.91px 2.73px 0.91px #00000026;
  border-radius: 6px;
}

.product-plan-edit-btn img {
  height: 20px;
  width: 20px;
}

.product-card-details .details-column {
  margin: 10px 0;
  font-size: 14px;
}

.product-card-details .details-column .status {
  padding: 2px 8px;
  font-size: 13px;
  border-radius: 6px;
}

.product-card-details .details-column .status.active {
  color: #28523e;
  background: #36d38836;
  border: 1px solid #36d388;
}

.product-card-details .details-column .status.inactive {
  color: #600303;
  background: #ffe2e2;
  border: 1px solid #b71212;
}

.theme-form-btn .btn {
  padding: 6px 20px;
}

.theme-cancel-btn {
  padding: 6px 20px;
  color: #344054;
  border: 1px solid#98A2B3;
  border-radius: 6px;
  font-size: 14px;
}

.product-plans-edit div#quix-dashboard-popup {
  width: unset;
  left: unset;
  right: 40px;
  top: 40px;
}

.product-plans-edit div.quix-dashboard-popup-inner {
  border-radius: 10px;
  box-shadow: 0px 0.91px 2.73px 0.91px #00000026;
}

/* extension settings */
.ext-image-uploaders {
  padding: 18px 20px;
}

.settings-content-card label{
  padding: 0;
}

.settings-content-card h5.title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
}

.extension-name {
  display: flex;
  align-items: center;
  width: 32%;
}

.extension-name-title {
  font-weight: 600;
  margin: 0;
  margin-right: 10px;
}

.extension-name input.form-control {
  flex: 1;
  max-width: 100%;
}

.ext-img-uploader-outer {
  display: flex;
  gap: 18px;
}

.ext-img-uploader-outer .settings-content-card {
  width: 50%;
}

.ext-settings-card-outer {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  width: 100%;
}

.ext-settings-card-outer .settings-content-card {
  flex: 1 1 calc(25% - 32px);
  box-sizing: border-box;
}

.settings-content-card .card-header {
  padding: 10px 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #eaecf0;
}

.settings-content-card {
  border-radius: 6px;
  border: none;
  box-shadow: 0px 0.43px 3.28px 0.4px #00000026;
  overflow: hidden;
  height: 100%;
}

.settings-content-card .card-lable {
  font-weight: 600;
}

.settings-content-card input {
  border-radius: 5px;
}

.color-options .color-bar-outer {
  height: 36px;
  width: 40px;
  position: relative;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.color-options input.color-bar {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.general-settings-outer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 16px;
  padding: 16px;
  box-sizing: border-box;
}

.general-settings-outer .card {
  display: flex;
  flex-direction: column;
  height: max-content;
}

/*  */
.dash-report-card {
  border-radius: 5px;
  border: 0px;
  box-shadow: 0px 0.43px 3.28px 0.4px #00000026;
}

.dash-report-card .card-body {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 16px;
}

.dash-report-card .card-body .card-badge {
  height: 65px;
  width: 65px;
  border-radius: 5px;
  padding: 6px;
}

select.filter-select-btn.btn {
  font-size: 14px;
  padding: 4px 10px;
  border: 1px solid #d6e5ff;
  width: 136px;
  height: 34px;
  text-align: justify;
  box-shadow: none;
}

.ocr-header-action-btn.btn {
  padding: 4px 10px;
  border: 1px solid #d6e5ff;
  font-size: 14px;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.ocr-header-action-btn.btn img {
  width: 20px;
  height: auto;
}

.status-outer-row {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(6, minmax(100px, 1fr));
}

.status-card.card {
  box-shadow: 0px 0.43px 3.28px 0.4px #00000026;
}

.status-card.card .card-body {
  padding: 6px 10px;
  font-weight: 600;
}

.status-card.card .card-body h5 {
  font-weight: 600;
}

.status-card.card.success-card {
  background-color: rgba(20, 90, 50, 0.2);
  border: 1px solid rgb(20, 90, 50);
  color: rgb(20, 90, 50);
}

.status-card.card.multi-choise-card {
  background-color: rgb(255, 165, 0, 0.2);
  border: 1px solid rgb(255, 165, 0);
  color: rgb(255, 165, 0);
}

.status-card.card.bad-reqest-card {
  background-color: rgba(192, 57, 43, 0.2);
  border: 1px solid rgb(192, 57, 43);
  color: rgb(192, 57, 43);
}

.status-card.card.not-found-card {
  background-color: rgba(255, 87, 34, 0.2);
  border: 1px solid rgb(255, 87, 34);
  color: rgb(255, 87, 34);
}

.status-card.card.internet-server-card {
  background-color: rgb(211, 47, 47, 0.2);
  border: 1px solid rgb(211, 47, 47);
  color: rgb(211, 47, 47);
}

.status-card.card.unavailable-card {
  background-color: rgb(211, 211, 211, 0.2);
  border: 1px solid rgb(211, 211, 211);
  color: rgb(211, 211, 211);
}

.report-card-outer .quix-dashboard-details-card:nth-child(1) .dash-report-card .card-badge {
  background-color: rgba(20, 90, 50, 0.2);
  border: 1px solid rgb(20, 90, 50);
}

.report-card-outer .quix-dashboard-details-card:nth-child(2) .dash-report-card .card-badge {
  background-color: rgba(192, 57, 43, 0.2);
  border: 1px solid rgb(192, 57, 43);
}

.report-card-outer .quix-dashboard-details-card:nth-child(3) .dash-report-card .card-badge {
  background-color: rgba(156, 39, 176, 0.2);
  border: 1px solid rgb(156, 39, 176);
}

.report-card-outer .quix-dashboard-details-card:nth-child(4) .dash-report-card .card-badge {
  background-color: rgba(255, 193, 7, 0.2);
  border: 1px solid rgb(255, 193, 7);
}

.extension-reports .card,
.extension-request-table .card {
  border-radius: 6px;
  border: 0px;
  box-shadow: 0px 0.43px 3.28px 0.4px #00000026;
  overflow: hidden;
  height: 100%;
}

.extension-reports .card-header,
.extension-request-table .card-header {
  background-color: #fff;
}

/*  */
.extension-request-table table tr td,
.extension-request-table table tr th {
  text-align: center;
}

.extension-request-details.row {
  display: flex;
}

.extension-request-details.row .extension-reports {
  width: 50%;
}

@media only screen and (max-width: 1640px) {
  .extension-request-details.row {
    display: block;
  }

  .extension-request-details.row .extension-reports {
    width: 100%;
    margin-bottom: 20px;
  }
}