/* Home Page */
* {
  box-sizing: border-box;
}

.navbar-nav {
  padding-left: 50px;
}

.navbar-nav.ms-auto {
  position: relative;
}

.navbar-nav.ms-auto::after {
  position: absolute;
  height: 18px;
  width: 2px;
  content: '';
  background-color: #CBCBCB;
  left: 20px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.justify-content-center {
  align-items: center;
}

.chrome-extensionbtn {
  width: 250px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  background: linear-gradient(8.29deg, #000000 -3.85%, #4a5051 93.64%);
  border-radius: 32px;
  font-family: "Mulish";
}

.turn_scree_captures .chrome-extensionbtn {
  color: #141518;
  border: 1px solid #141518;
  background: #ffffff;
  box-shadow: 0px 20px 45px rgba(0, 0, 0, 0.2);
  border-radius: 18px;
}

.left-content {
  width: 60%;
  margin: auto;
}

.left-content {
  width: 60%;
  margin: auto;
}

/*  */
#screen-capture {
  margin-top: 5rem;
  margin-bottom: 1rem;
}

#screen-capture .section_title h2 {
  position: relative;
  width: max-content;
  margin: 0 auto;
  margin-bottom: 10px;
}

#screen-capture .section_title h2::before {
  position: absolute;
  height: 70px;
  width: 60px;
  content: '';
  background-image: url('../../assets/images/home/ocr-captur-title-badge.png');
  background-repeat: no-repeat;
  background-size: 100%;
  left: 0;
  top: 0;
  transform: translate(-75%, -50%);
}

.heading-badge-text {
  position: relative;
  color: #2F51B5;
}

.heading-badge-text::after {
  position: absolute;
  content: '';
  height: 100%;
  width: 106%;
  left: 0%;
  top: 0%;
  padding: 0px 20px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  z-index: -1;
}

#screenshot-smarter .heading-badge-text::after {
  background-image: url('../../assets/images/home/ocr-title-line1.png');
}

#screen-recording-tab .heading-badge-text::after {
  background-image: url('../../assets/images/home/ocr-title-line2.png');
}

#screenshot-tab .heading-badge-text::after {
  background-image: url('../../assets/images/home/ocr-title-line3.png');
}

#pricing-plans-tab .heading-badge-text::after {
  background-image: url('../../assets/images/home/ocr-title-line4.png');
}

.stylish-masterpieces-sec {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.stylish-masterpieces-column-sec {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 20px;
}

.stylish-masterpieces-column:nth-child(1) .stylish-masterpieces-column-sec:nth-child(2),
.stylish-masterpieces-column:nth-child(3) .stylish-masterpieces-column-sec:nth-child(2) {
  justify-content: center;
}

.stylish-masterpieces-column:nth-child(3) .stylish-masterpieces-column-sec:nth-child(1) {
  justify-content: end;
}

.stylish-masterpieces-column .stylish-masterpieces-column-img {
  height: auto;
  width: 60px;
}

.stylish-masterpieces-column .stylish-masterpieces-column-img img {
  height: auto;
  width: 100%;
}

.stylish-masterpieces-column span {
  font-weight: 600;
}

.stylish-masterpieces-title h2 {
  font-weight: 600;
  background-color: #2F51B5;
  color: #fff;
  padding: 6px 10px;
  margin: 0 auto;
  width: max-content;
  position: relative;
}

.stylish-masterpieces-title h2::after {
  position: absolute;
  height: 92px;
  width: 350px;
  content: '';
  background-image: url('../../assets/images/home/ocr-title-background.png');
  background-repeat: no-repeat;
  background-size: 100% 70%;
  left: 60%;
  top: 0%;
  transform: translate(-50%, 0%);
  z-index: -1;
}

.stylish-masterpieces-column span.creating-impressing {
  padding: 20px 0;
  display: block;
  font-weight: 100;
}

/*  */
.screen_recording_game .conversations-mid span h5,
.screen_recording_game .conversations-mid span p {
  color: #121c2c;
  margin-top: 10px;
}

.frequently_asked_questions span {
  width: 100%;
  display: inline-block;
}

.section_title {
  width: 100%;
  text-align: center;
  margin: 4rem 0 0 0;
}

.container-fluid.frequently_asked_questions {
  margin: 5rem 0px;
}

.container-fluid.frequently_asked_questions {
  margin: 2rem 0px;
  padding-bottom: 1%;
}

.footer {
  background: #2F51B5;
  color: #fff;
  margin-bottom: 0px;
  padding: 40px 0px;
  padding-bottom: 0px;
  float: right;
  width: 100%;
}

.social_icon_copy_right {
  padding-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid #d2d2d2;
  margin-top: 25px;
}

.social_icon_copy_right img {
  width: 18px;
  margin-right: 6px;
}

.Quixy_Logo img {
  width: auto;
  height: 26px;
}

.footer ul li {
  list-style: none;
}

.footer ul li a {
  color: #fff;
  text-decoration: none;
}

a.support-email {
  color: #fff;
}

.footer p.vividminds-tech-dec,
.social_icon_copy_right .Copyright {
  color: #f3f2ff7d !important;
}

.social_icon_copy_right .Copyright,
.social_icon_copy_right .Quixy_Logo {
  text-align: center;
  width: 100%;
  display: inline-block;
  font-size: 14px;
}

.social_icon_copy_right span {
  vertical-align: middle;
}

.login {
  color: #2F51B5 !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  box-sizing: border-box;
  padding: 5px 25px !important;
  font-family: "Mulish";
}

.get_started {
  border: 1px solid #2F51B5;
  border-radius: 5px;
  color: #ffffff !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  box-sizing: border-box;
  margin-left: 15px;
  background: #2F51B5;
  padding: 5px 25px !important;
}

.features_in_beta {

  margin-top: 2rem;
}

.turn_scree_captures_feature p {
  margin-top: 10px;
  margin-bottom: 0px;
}

.features_in_beta_plan {
  text-align: center;
}

.features_in_beta .section_title {
  margin: 4rem 0 1rem;
  padding: 0 20%;
}

span.features_in_beta_plan_span {
  padding: 3% 0%;
  border: 1px solid #c7c7c8;
  border-radius: 5px;
  background: #ffffff;
  display: inline-block;
  width: 100%;
  max-width: 420px;
}

span.features_in_beta_plan_span ul li {
  padding: 3% 5%;
}

.home-page .section_title h2 {
  font-weight: 700;
}

#pricing-plans-tab h1 {
  font-weight: 700;
}

.features_in_beta_plan_span h5 {
  font-size: 28px;
  text-align: justify;
  padding: 0 36px;
}

.features_in_beta_plan_span p {
  color: #000 !important;
  font-weight: 500;
  text-align: justify;
  padding: 0 36px;
}

.features_in_beta_plan_span img {
  height: 26px;
  width: 26px;
}

ul li {
  list-style: none;
}

ul {
  padding: 0px;
}

ul li span {
  display: inline-block;
  width: 80%;
  text-align: left;
  vertical-align: middle;
  padding-left: 10px;
  opacity: 0.6;
}

.section-heading {
  font-size: 3rem;
  line-height: 130%;
}

.section-heading span.chrome-badge {
  position: relative;
  color: #2F51B5;
}

.section-heading span.chrome-badge::after {
  position: absolute;
  content: '';
  height: 100%;
  width: 45px;
  background: url('../../assets/images/home/chrom-badge.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 100%;
  right: 0;
  bottom: 0%;
  transform: translate(30px, 24px);
}

.chrome-thisbtn {
  background-color: #2F51B5;
  border-radius: 5px;
  width: 80%;
  font-family: "Lato";
  padding: 14px 0px;
}

.chrome-thisbtn img.chrome-icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.chrome-thisbtn span {
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.46);
}

.home-page .col-lg-6.conversations-mid span h5 {
  margin: 10px 0px;
}

.home-page p {
  color: #727374 !important;
  font-family: "Mulish";
}

div#frequently-asked-questions span p,
div#frequently-asked-questions span h6 {
  font-size: 18px;
  font-family: "Mulish";
}

#frequently-asked-questions .section_title {
  margin-top: 0;
}

.footer p {
  color: #fff !important;
  font-size: 14px;
}

div#pricing-plans-tab {
  padding: 0rem 0;
  padding-bottom: 2rem;
}

div#frequently-asked-questions {
  padding: 2rem 0;
}

div#frequently-asked-questions .accordion-item {
  border: none;
}

div#frequently-asked-questions .accordion-button:not(.collapsed) {
  color: #282828;
  background-color: unset;
  box-shadow: none;
}

div#frequently-asked-questions .accordion-body {
  padding: 1rem 1.25rem;
  border-bottom: 1px solid #E2E2E2;
}

div#frequently-asked-questions .accordion-header h5 {
  color: #282828;
  font-size: 1.25rem;
}

div#frequently-asked-questions .accordion-button {
  align-items: unset;
}

div#frequently-asked-questions .accordion-button::after {
  background-image: url('../../assets/images/home/ocr-accodion-arrow-down.png');
  margin-top: 6px;
}

div#frequently-asked-questions .accordion-button:not(.collapsed)::after {
  background-image: url('../../assets/images/home/ocr-accodion-arrow-down.png');

}

.accordion-button:not(.collapsed)::after
/* Home Page */

/* Thank you Page */
.thankyou-page .header_section {
  background-image: none;
}

.justify-content-center {
  align-items: center;
}

.left-content {
  width: 60%;
  margin: auto;
}

.left-content {
  width: 60%;
  margin: auto;
}

.col-lg-6.conversations-mid .screenshot_smarter,
.col-lg-6.conversations-mid .take_your_screen {
  width: 100%;
  display: flex;
  padding: 10px 10px;
  border-radius: 12px;
  margin: 1rem;
  vertical-align: middle;
  color: #000;
  cursor: pointer;
  border: 1px solid transparent;
  float: left;
}

.col-lg-6.conversations-mid .screenshot_smarter img,
.col-lg-6.conversations-mid .take_your_screen img {
  width: 60px;
  height: 60px;
  margin: 12px;
}

/* screen_recording_game */
.frequently_asked_questions {
  color: #000;
}

.section_title {
  width: 100%;
  text-align: center;
  margin: 5rem 0 0 0;
}

.footer ul li {
  list-style: none;
  text-align: left;
  margin: 0px 0px 7px 0px;
  padding-left: 25%;
}

.screenshots img {
  width: 100%;
}

.footer .sitetitle {
  width: 120px;
  margin-bottom: 10px;
}

/* adding css */
.exten_screen {
  display: inline-block;
  background: #fff;
  width: 30%;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  border: 1px solid #ededed;
  position: relative;
  height: 380px;
  vertical-align: middle;
  text-align: left;
  margin: 5% 15px;
  box-shadow: 0 0 5px #ededed;
}

.exten_screen img {
  position: absolute;
  bottom: 13px;
  left: 15px;
  width: 90%;
}

.exten_screen span {
  width: 90%;
  display: inline-block;
}

.exten_screen span h5 {
  color: #121c2c;
  font-size: 35px;
  font-weight: 800;
}

.extension_screen {
  margin-bottom: 10%;
}

.section_title h2 {
  font-size: 40px;
  font-weight: 600;
}

.section_title p {
  font-size: 20px;
  margin-top: 20px;
  color: #727374;
  font-weight: 400;
}

/* adsense - thankupage / uninstall page */
/* .adsense-outer {
  position: relative;
} */

.adsense-bar {
  height: 100px;
  width: 300px;
  position: fixed;
  top: 15%;
}

.adsense-left-bar {
  left: 0%;
}

.adsense-right-bar {
  right: 0%;
}

/* uninstall-page */
.uninstall-page,
.about-us-page,
.quixy-privacy-policy {
  position: relative;
}

.uninstall-page .adsense-bar,
.about-us-page .adsense-bar,
.quixy-privacy-policy .adsense-bar {
  min-height: 100px;
  position: absolute;
  top: 15%;
}

/* adsense - thankupage / uninstall page end */
/* Thank you Page */
.activity-bnnr-innr {
  position: relative;
  background-image: url('');
}

.activity-bnnr-innr::before {
  position: absolute;
  content: '';
  height: 200px;
  width: 200px;
  background-image: url('../../assets/images/home/ocr-activity-top-badge.png');
  background-size: 100%;
  background-repeat: no-repeat;
  left: 3%;
  top: 4%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.activity-bnnr-innr::after {
  position: absolute;
  content: '';
  height: 150px;
  width: 150px;
  background-image: url('../../assets/images/home/ocr-activity-bottom-badge.png');
  background-size: 100%;
  background-repeat: no-repeat;
  right: 3%;
  bottom: 5%;
  transform: translate(50%, 50%);
  z-index: -1;
}

.activity-steps-column {
  padding: 2rem 0;
  margin-top: 5rem;
}

.activity-steps-1 {
  background: linear-gradient(180deg, #F2F5FF 80%, transparent 20%);
}

.activity-steps-2 {
  background: linear-gradient(180deg, #F3F2FF 80%, transparent 20%);
}

.activity-steps-3 {
  background: linear-gradient(180deg, #F2FEFF 80%, transparent 20%);
}

.activity-steps-column-inner .section_title {
  margin: 0px;
}

.quix-dashboard-slider-outer {
  margin: 4rem 0 0;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}

.activity-steps-card {
  padding: 20px;
}

.activity-steps-content {
  padding: 15px;
}

.step-counts {
  display: flex;
}

.step-counts h4 {
  font-size: 3rem;
  font-weight: 600;
}

.step-counts span {
  color: #585F68;
  margin: 0px 10px;
}

.step-discription p {
  font-size: 18px;
  color: #292D32;
}

.activity-steps-banner img {
  height: 100%;
  width: 100%;
  max-height: 520px;
}

.emoji img {
  height: auto;
  width: 100%;
}

.emoji_content .section_title .heading-badge-text {
  border-bottom: 2px solid #2F51B5;
}

.extension_screen {
  margin-bottom: 0%;
  padding: 0% 5%;
  border-radius: 24px;
  text-align: center;
}

.uninstall-page .extension_screen {
  margin-top: 60px;
}

.feedback-form .section_title h5 {
  font-weight: 600;
}

.uninstall-page .feedback-section {
  background-color: #ededed;
}

.feedback-form {
  background-color: #ffff;
  padding: 40px 40px;
  border-radius: 25px;
  border: 1px solid #00000040;
}

.uninstall-page .conversations-mid input.form-control {
  border-radius: 5px;
}

.btn.quix-radio-btn {
  padding: 3px 6px;
  color: #000;
  font-size: 13px;
  border-radius: 6px;
  border: 1px solid #00000040;
}

.feedback-form .form-row input[type="radio"]:checked+.quix-radio-btn {
  color: #fff;
  background: #2F51B5;
}

.feedback-form input.form-control,
.feedback-form input.form-control:focus,
.feedback-form textarea.form-control,
.feedback-form textarea.form-control:focus {
  border: 1px solid #00000040;
  border-radius: 5px;
}

.uninstall-page .btn_section_row button.submitbtn {
  background: #2F51B5;
  color: #fff;
  border: 1px solid #2F51B5;
  padding: 6px 60px;
  margin-top: 30px;
  position: relative;
}

.btn_section_row {
  text-align: center;
  /* padding-bottom: 3%; */
}

span.emoji_content h5 {
  font-size: 28px;
}

.ocr-popup-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 350px;
  width: 580px;
  z-index: 111;
}

.ocr-popup-modal .ocr-banner-card,
.ocr-popup-modal .ocr-banner-card img {
  height: 100%;
  width: 100%;
}

.ocr-popup-modal .ocr-banner-card img.close-ocr-modal {
  height: 24px;
  width: 24px;
  position: absolute;
  right: 50px;
  top: 21px;
  cursor: pointer;
}

.btn_section_row button {
  border: 1px solid #000000;
  background: #fff;
  color: #000;
  padding: 5px 30px;
  margin: 5px 7px;
  border-radius: 7px;
}

.btn_section_row button:hover {
  background: transparent;
  color: #000;
}

.btn_section_row button.submitbtn {
  background: #000;
  color: #fff;
}

.form-control {
  border-radius: 10px;
}

.form-row {
  margin: 2% 0%;
}

.btn_section_row img.loader-image {
  width: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.thank-you-screen {
  display: none;
  margin-bottom: 10%;
  padding: 0% 5%;
  border: 1px solid #ededed;
  border-radius: 24px;
  text-align: center;
}

.thank-you-screen-title span {
  font-size: 32px;
  font-weight: 600;
  color: green;
}

/* uninstall Page */
div#quix-important-message {
  background: #000;
  text-align: center;
  color: #d16363;
  font-size: 13px;
  padding: 10px;
  font-weight: 600;
}

.uninstall-page label {
  font-size: 16px;
}

#frequently-asked-questions span {
  margin-bottom: 20px;
}

.ScreenGenius-mid .carousel-indicators [data-bs-target] {
  background-color: #353333;
}

.footer p span {
  float: left;
  width: 100%;
}

#elevate-capture {
  background: linear-gradient(90deg, #2F51B5 55%, transparent 45%);
  padding: 50px 0;
  margin-bottom: 20px;
}

.elevate-your-capture-inner {
  display: flex;
}

.elevate-your-capture-column-content {
  height: 100%;
  justify-content: center;
  color: #ffff;
  font-weight: 600;
}

#elevate-capture .chrome-thisbtn {
  padding: 14px 20px;
  width: max-content;
  background-color: #fff;
  border: 1px solid #fff;
  color: #000;
  margin-top: 25px;
}

#elevate-capture .chrome-thisbtn span {
  color: rgba(12, 12, 12, 0.46);
}

.elevate-your-capture-column-img {
  position: relative;
}

.elevate-your-capture-column-img .elevate-badge {
  position: absolute;
  height: auto;
  width: 46px;
}

.elevate-your-capture-column-img .elevate-badge:nth-child(2) {
  left: 0%;
  top: 40%;
  transform: translate(-0%, -50%);
}

.elevate-your-capture-column-img .elevate-badge:nth-child(3) {
  right: 46px;
  top: 40%;
  transform: translate(-0%, -50%);
}

.elevate-your-capture-column-img .elevate-badge:nth-child(4) {
  left: 25%;
  bottom: 0%;
  transform: translate(-10%, 0%);
}

/* about page */
div.main-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
}

div.main-wrapper .content-section {
  flex: 1;
}

@media only screen and (max-width: 1860px) {
  .satisfied-row .satisfied-column.d-flex {
    display: block !important;
    text-align: justify;
  }
}

@media only screen and (max-width: 1460px) {
  .reinstall-row .reinstall-column.d-flex {
    display: block !important;
    text-align: justify;
  }
}

@media only screen and (max-width: 1460px) {
  .reinstall-row .reinstall-column.d-flex {
    display: block !important;
    text-align: justify;
  }
}

@media only screen and (max-width: 1200px) {
  .navbar-nav {
    padding-left: 14% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .header_section {
    background-image: none;
  }

  .footer ul li {
    text-align: left;
    padding-left: 0px;
  }

  span.features_in_beta_plan_span {
    width: 60%;
  }

  a.nav-item.nav-link.get_started,
  a.nav-item.nav-link.login {
    max-width: 150px !important;
    text-align: center !important;
  }

  a.nav-item.nav-link.get_started {
    padding: 5px 0px !important;
    margin-top: 10px;
    margin-left: 0px;
  }

  .navbar-nav {
    padding: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .features_in_beta .section_title {
    padding: 0 10%;
  }

  .uninstall-page label {
    text-align: left;
    font-size: 13px;
    float: left;
  }

  .footer ul li {
    text-align: left;
    padding-left: 0px;
  }

  .footer .col-lg-4 {
    margin: 10px 0px;
  }

  .social_icon_copy_right .col-lg-4 {
    text-align: center;
    margin: 5px 0px;
  }

  .col-lg-6.conversations-mid span {
    width: 100% !important;
    margin: 10px 0px !important;
  }

  span.features_in_beta_plan_span {
    width: 100%;
  }

  .container-fluid.turn_scree_captures_section {
    margin-bottom: 2rem;
  }

  .col-lg-6.d-flex.flex-column.justify-content-center.conversations-mid img {
    max-width: 100%;
  }

  .features_in_beta {
    padding: 0% 0;
  }

  .container-fluid.frequently_asked_questions {
    margin: 10px 0px;
  }

  .footer {
    margin: 1rem 0;
    padding: 15px 0px;
  }

  .social_icon_copy_right {
    text-align: center;
  }

  .left-content {
    width: 100%;
    margin-bottom: 20px;
  }

  div#screen-recording-tab {
    padding: 2rem 0px;
  }

  .social_icon_copy_right .Copyright,
  .social_icon_copy_right .Quixy_Logo {
    padding: 5px 0px;
  }

  .Quixy_Logo img {
    position: relative;
    top: 5px;
  }

  .header_section {
    background-image: none !important;
  }

  .left-content {
    width: 90% !important;
    margin: 0% auto !important;
    margin-bottom: 5% !important;
  }

  a.nav-item.nav-link.get_started,
  a.nav-item.nav-link.login {
    max-width: 150px !important;
    text-align: center !important;
  }

  .navbar-nav {
    padding: 0px !important;
  }

  a.nav-item.nav-link.get_started {
    padding: 5px 0px !important;
    margin-top: 10px;
    margin-left: 0px;
  }

  .features_in_beta {
    background-image: none;
  }
}

@media only screen and (max-width: 767px) {
  span.thankEmoji {
    width: 90%;
  }

  span.emoji {
    width: 100%;
  }

  .row {
    padding: 0px !important;
    margin: 0px !important;
  }

  .section_title {
    margin: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .extension_screen {
    text-align: center;
    width: 80%;
    margin: 5% auto;
  }

  .exten_screen {
    text-align: left;
    width: 100%;
    margin: 5% 0%;
  }

  .thankyou-page .section_title {
    margin-top: 5%;
  }

  .exten_screen img {
    left: 10px;
    width: 90%;
  }
}