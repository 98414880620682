* {
    margin: 0px;
    padding: 0px;
}

.quixy-privacy-policy .container {
    max-width: 1140px;
    margin: 0 auto;
}

.quixy-privacy-policy .page-content {
    padding: 40px 6%;
    text-align: justify;
}

.quixy-privacy-policy h1.main-heading {
    font-size: 22px;
    font-weight: 600;
}

.quixy-privacy-policy p.heading-text {
    font-size: 16px;
    margin: 20px 0;
}

.quixy-privacy-policy h2.page-terms-heading {
    font-size: 22px;
    font-weight: 600;
    margin: 20px 0;
}

.quixy-privacy-policy h3.page-terms-sub-heading {
    font-size: 16px;
    font-weight: 600;
    margin: 12px 0;
}

.quixy-privacy-policy div.page-terms-description-column {
    margin: 15px 0;
}

.quixy-privacy-policy p.page-terms-description {
    font-size: 15px;
    font-weight: normal;
    margin: 10px 0;
}

.quixy-privacy-policy a.page-email-link {
    font-size: 15px;
    font-weight: normal;
    color: #3693e5;
    margin: 0 6px;
}